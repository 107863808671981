@font-face {
    font-family: 'WorkSansLight';
    src: url('fonts/WorkSans-Light.woff') format('woff');
    font-weight: 300; /* жирность шрифта: легкий */
}
  
@font-face {
    font-family: 'WorkSansRegular';
    src: url('fonts/WorkSans-Regular.woff') format('woff');
    font-weight: 400;
}
  
@font-face {
    font-family: 'WorkSansMedium';
    src: url('fonts/WorkSans-Medium.woff') format('woff');
    font-weight: 500;
}
  
@font-face {
    font-family: 'WorkSansBold';
    src: url('fonts/WorkSans-Bold.woff') format('woff');
    font-weight: 600;
}
  
.App {
  width: 100%;
  font-family: "WorkSansRegular", sans-serif;
  font-weight: normal;
}
  
.main {
  padding-top: 100px;
  padding-bottom: 20px;
}
  
.login {
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.login__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #594d87;
    width: 500px;
    height: 400px;
    border-radius: 10px;
    color: #fff;
  }
  
.login__part {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
.login__input {
    border: 1px solid #413c56;
    padding: 5px;
    color: #fff;
  }
  
.login__btn {
    background-color: #413c56;
    color: #fff;
    padding: 10px;
    width: 80px;
    border-radius: 5px;
    cursor: pointer;
  }
  
.cards-list {
  margin: 0 auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
  
.cards-list__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  
.cards-list__arrow {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.cards-list__arrow_img {
      width: 15px;
      height: 15px;
    }
  
.cards-list__arrow_img_prev {
        transform: rotate(180deg);
      }
  
.cards-list__page_btn {
      background-color: #fff;
      width: 30px;
      height: 30px;
      padding: 2px;
      font-size: 14px;
      border-radius: 3px;
    }
  
.cards-list__ellipsis {
    color: #fff;
  }
  
.card {
  width: 100%;
  min-height: 40vh;
  background-color: #413c56;
  color: #fff;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
}
  
.card__container {
    display: flex;
    width: 100%;
    min-height: 40vh;
  }
  
.card__imgs {
    position: relative;
    width: 23%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.card__imgs_prev {
      height: 15px;
      width: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-left: 5px;
    }
  
.card__imgs_next {
      height: 15px;
      width: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-right: 5px;
    }
  
.card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.card__middle {
    width: 60%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
.card__texts {
    width: 100%;
  }
  
.card__title {
    width: 100%;
    font-size: 2.4vw;
    margin-bottom: 20px;
  }
  
.card__desc {
    width: 100%;
    font-size: 1.1vw;
    height: 17vh;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    display: -webkit-box;
  }
  
.card__tags {
    display: flex;
    width: 100%;
    gap: 15px;
    margin-top: 20px;
  }
  
.card__tag {
    background-color: #594d87;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
  }
  
.card__show svg {
    cursor: pointer;
  }
  
.card__copy {
    cursor: pointer;
    height: 40px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 0 10px 0 10px;
    font-size: 1vw;
  }
  
.slider-dots {
  display: flex;
  gap: 10px;
  padding-bottom: 5px;
}
  
.slider-dots-dot {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 100%;
  }
  
.slider-dots-dot.active {
      background-color: #545454;
    }
  
.slider-container {
  height: 100%;
}
  
.slider-frame {
  height: 100% !important;
}
  
.slider-list {
  height: 100%;
}
  
.slide {
  height: 100% !important;
}
  
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  background-color: #030805;
  z-index: 1000;
}
  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure {
  margin: 0;
}
  
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "WorkSansBold", sans-serif;
  font-weight: 600;
}
  
ul {
  padding: 0;
  list-style: none;
}
  
a {
  text-decoration: none;
  cursor: pointer;
}
  
p {
  padding: 0;
}
  
input,
select,
textarea,
button {
  outline: none !important;
  border: none;
  background: none;
}
  
button {
  cursor: pointer;
}
  
img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
  
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
  
body {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: overlay;
  position: relative;
}
  
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #1f202e;
}
